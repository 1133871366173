const Termsandconditions = () => {
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Terms and conditions</h1>
                            <h2 className="page-description">Terms and conditions</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
            <div class="container">
              {/* <h3>TERMS & CONDITIONS </h3> */}
                <p>Role: Any registered User of the Site may choose to make rent payments,
                    maintenance payments and payment of security deposit/ token amounts, through the
                    payment gateway(s) authorized by Book a rent House . In this regard, the Users are
                    asked to provide customary billing information such as name, financial instrument
                    information which shall include the bank account number, IFSC code of the User, the
                    details of the landlord to whom the payment has to be made and the address of the
                    property with regard to which the rent or security deposit is to be paid. Users may also
                    be asked to provide a copy of the rental agreement pursuant to which such rent
                    payments are being made. The Users must provide accurate, current, and complete
                    information while making the payment through the Site and it shall be the User’s
                    obligation to keep this information up-to-date at all times. The Users are solely
                    responsible for the accuracy and completeness of the information provided by them and
                    Book a rent House shall not be responsible for any loss suffered by the User as a result of any
                    incorrect information, including payment information provided by the Users</p>
                <p>Book a rent house is neither an agent of the lessor or lessee or any registered User.
                    Book a rent House acts solely as an intermediary which facilitates payments between
                    the registered Users making the payment</p>
                <p>Fees: The User agrees that they may be charged a service charge by Book a rent house
                    or using the Site to make a House owners advance payments and tentant Brokarage
                    rental payments.</p>    
              </div>
            </div>
        </section>
    )
}

export default Termsandconditions