import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logoimage from '../logo-icon.jpg'
import { HomeOutlined, MailFilled, MailOutlined, MobileOutlined, MobileTwoTone } from "@ant-design/icons";

const Footer = () => {
    const [contactInfo, setContactInfo] = useState(null);
    const [categoryImages, setCategoryImages] = useState([]);

    const BASE_URL = "https://api.bookarenthouse.com"; // Base URL for image paths

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/profile`);
                const data = await response.json();
                setContactInfo(data[0]); // Assuming the API returns an array
            } catch (error) {
                console.error("Error fetching contact info:", error);
            }
        };

        const fetchCategoryImages = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/photogallery`);
                const data = await response.json();
                setCategoryImages(data.files); // Assuming the API returns an object with a 'files' array
            } catch (error) {
                console.error("Error fetching category images:", error);
            }
        };

        fetchContactInfo();
        fetchCategoryImages();
    }, []);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <section style={styles.footer}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div style={{ backgroundColor: "white", padding: 10 }}>
                            <img src={logoimage} style={{ height: 40, width: 60 }} />
                        </div>
                        {/* <i className="fas fa-home" style={styles.homeIcon}></i> */}
                        <span style={styles.footerOtherText} className="footer-other-text d-block mt-3 mb-3">
                            At Book a Rent House, we are dedicated to delivering innovative solutions that drive success. With a commitment to quality and a passion for excellence.</span>
                        <div style={styles.socialContainer}>
                            {contactInfo && (
                                <>
                                    {contactInfo.facebook && (
                                        <div style={styles.socialItem} className="footer-social-item">
                                            <a href={contactInfo.facebook} target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={e => e.currentTarget.style.background = '#4e555d'}
                                                onMouseLeave={e => e.currentTarget.style.background = '#393a42'}>
                                                <i className="fab fa-facebook" style={styles.socialIcon}></i>
                                            </a>
                                        </div>
                                    )}
                                    {contactInfo.twitter && (
                                        <div style={styles.socialItem} className="footer-social-item">
                                            <a href={contactInfo.twitter} target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={e => e.currentTarget.style.background = '#4e555d'}
                                                onMouseLeave={e => e.currentTarget.style.background = '#393a42'}>
                                                <i className="fab fa-twitter" style={styles.socialIcon}></i>
                                            </a>
                                        </div>
                                    )}
                                    {contactInfo.instagram && (
                                        <div style={styles.socialItem} className="footer-social-item">
                                            <a href={contactInfo.instagram} target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={e => e.currentTarget.style.background = '#4e555d'}
                                                onMouseLeave={e => e.currentTarget.style.background = '#393a42'}>
                                                <i className="fab fa-instagram" style={styles.socialIcon}></i>
                                            </a>
                                        </div>
                                    )}
                                    {contactInfo.linkedin && (
                                        <div style={styles.socialItem} className="footer-social-item">
                                            <a href={contactInfo.linkedin} target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={e => e.currentTarget.style.background = '#4e555d'}
                                                onMouseLeave={e => e.currentTarget.style.background = '#393a42'}>
                                                <i className="fab fa-linkedin" style={styles.socialIcon}></i>
                                            </a>
                                        </div>
                                    )}
                                    {contactInfo.youtube && (
                                        <div style={styles.socialItem} className="footer-social-item">
                                            <a href={contactInfo.instagram} target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={e => e.currentTarget.style.background = '#4e555d'}
                                                onMouseLeave={e => e.currentTarget.style.background = '#393a42'}>
                                                <i className="fab fa-instagram" style={styles.socialIcon}></i>
                                            </a>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <p style={styles.footerTitle}>Quick Links</p>
                        <ul style={styles.footerUl}>
                            <li style={styles.footerLi}>
                                <Link to="/about" style={{ color: '#ffffff8a', textDecoration: 'none', transition: 'color 0.3s' }}
                                    onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                    onMouseLeave={e => e.currentTarget.style.color = '#ffffff8a'}>
                                    About Us
                                </Link>
                            </li>
                            <li style={styles.footerLi} onClick={scrollToTop}><Link to="/contact" style={{ color: '#ffffff8a', textDecoration: 'none', transition: 'color 0.3s' }}
                                onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                onMouseLeave={e => e.currentTarget.style.color = '#ffffff8a'}>
                                Contact Us
                            </Link></li>
                            <li style={styles.footerLi} onClick={scrollToTop}><Link to="/refundpolicy" style={{ color: '#ffffff8a', textDecoration: 'none', transition: 'color 0.3s' }}
                                onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                onMouseLeave={e => e.currentTarget.style.color = '#ffffff8a'}>
                                Refund Policy
                            </Link></li>
                            <li style={styles.footerLi} onClick={scrollToTop}> <Link to="/privacypolicy" style={{ color: '#ffffff8a', textDecoration: 'none', transition: 'color 0.3s' }}
                                onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                onMouseLeave={e => e.currentTarget.style.color = '#ffffff8a'}>
                                Privacy Policy
                            </Link></li>
                            <li style={styles.footerLi} onClick={scrollToTop}><Link to="/termsandconditions" style={{ color: '#ffffff8a', textDecoration: 'none', transition: 'color 0.3s' }}
                                onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                onMouseLeave={e => e.currentTarget.style.color = '#ffffff8a'}>
                                Terms and conditions
                            </Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 categoryContainer">
                        <p style={styles.footerTitle}>Photo Galllery</p>
                        <div className="row">
                            {categoryImages.length > 0 ? (
                                categoryImages.map((image, index) => (
                                    <div key={index} className="col-4" style={styles.categoryItem}>
                                        <img
                                            src={`${BASE_URL}${image}`}
                                            alt={`Category ${index + 1}`}
                                            className="img-fluid"
                                            style={styles.categoryImage}
                                            onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                                            onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="col-12">No images available</div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <p style={styles.footerTitle}>Contact</p>
                        <ul style={styles.footerUl}>
                            {contactInfo ? (
                                <>
                                    <li style={styles.footerLi} className="d-flex">
                                        <div style={styles.footerInfoItem}>
                                            <MailOutlined />
                                        </div>
                                        <span> &nbsp;{contactInfo.email}</span>
                                    </li>
                                    <li style={styles.footerLi} className="d-flex">
                                        <div style={styles.footerInfoItem}>
                                            <HomeOutlined />

                                        </div>                                        <span> &nbsp;{contactInfo.location}</span>
                                    </li>
                                    <li style={styles.footerLi} className="d-flex">
                                        <div style={styles.footerInfoItem}>
                                            <MobileOutlined />

                                        </div>                                        <span> &nbsp;{contactInfo.phoneNumber}</span>
                                    </li>
                                </>
                            ) : (
                                <li style={styles.footerLi}>Loading...</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;

const styles = {
    footer: {
        padding: '4rem 0',
        background: '#0e2e50',
        color: 'white',
    },
    socialIcon: {
        color: 'white',
        fontSize: '22px',
        transition: 'color 0.3s',
    },
    footerTitle: {
        fontSize: '18px',

        fontWeight: 'bold',
        marginLeft: '10px',
        marginBottom: '1rem',
    },
    footerUl: {
        padding: 0,
    },
    footerLi: {
        listStyle: 'none',
        marginLeft: '10px',
        marginBottom: '10px',
        fontSize: '15px',
        color: '#ffffff8a',
        transition: 'color 0.3s',
    },
    footerOtherText: {
        fontSize: '0.8rem',
        color: '#ffffff8a',
    },
    homeIcon: {
        fontSize: '40px',
    },
    socialContainer: {
        display: 'flex',
        gap: '5px', // Adding gap between social icons
    },
    socialItem: {
        width: '45px',
        height: '45px',
        background: '#393a42',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        transition: 'background 0.3s',
    },
    footerInfoItem: {
        color: 'white',
        width: '25px',
    },
    categoryItem: {
        padding: '10px',
        transition: 'transform 0.3s',
    },
    categoryImage: {
        border: '2px solid white',
        borderRadius: '5px',
        width: '100%',
        transition: 'transform 0.3s',
        height: '60px'
    },
    categoryContainer: {
        marginBottom: '2rem', // Add margin to separate from contact section
    },
};

