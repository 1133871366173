import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Typography, Modal, Form, Input } from 'antd';
import { PhoneOutlined, CalendarOutlined } from '@ant-design/icons';
import image from './appoinmentimage.jpg';

const { Title, Text } = Typography;

const ContactAgent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const hosturl ='https://api.bookarenthouse.com'
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${hosturl}/api/profile`);
        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            setPhoneNumber(data[0].phoneNumber); // Set the phone number from the fetched data
          }
        } else {
          console.error('Failed to fetch profile data');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const handleMakeCall = () => {
    window.location.href = `tel:+${phoneNumber}`; // Use the fetched phone number
  };

  const handleGetAppointment = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFormSubmit = async (values) => {
    console.log('Appointment details:', values);

    try {
      const response = await fetch(`${hosturl}/submit_appointment`, { // Replace with your actual API URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        alert('Appointment submitted successfully');
        setIsModalVisible(false); // Close the modal after successful submission
      } else {
        const errorText = await response.text();
        alert('Failed to submit appointment: ' + errorText);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting appointment');
    }
  };

  // Inline styles
  const styles = {
    container: {
      background: `url('path/to/your/dotted-pattern.png') repeat`,
      padding: '20px 0',
    },
    card: {
      maxWidth: '80%',
      margin: '0 auto',
      padding: 15,
      backgroundColor: 'white',
      borderRadius: 8,
      border: '2px dotted #31ca6a',
    },
   
    agentInfo: {
      paddingLeft: '24px',
    },
    agentButtons: {
      marginTop: 16,
    },
    callButton: {
      backgroundColor: '#31ca6a',
      borderColor: '#31ca6a',
      // marginRight: 8,
      // padding: '12px 24px',
      // fontSize: '16px',
      // minHeight: '48px',
    },
    appointmentButton: {
      backgroundColor: '#0d274f',
      borderColor: '#0d274f',
      // padding: '12px 24px',
      // fontSize: '16px',
      // minHeight: '48px',
    },
    iconSpacing: {
      marginRight: '8px',
    },
  };

  return (
    <div style={styles.container}>
      <Card style={styles.card} bordered={false}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={10}>
            <img
              src={image}
              alt="Certified Agent"
              style={{width:'100%'}}
              // style={styles.agentImage}
            />
          </Col>

          <Col xs={24} md={14} style={styles.agentInfo}>
            <Title level={3} style={{marginTop:10}} >Contact With Our Certified Agent</Title>
            <Text>
               Reach Our Certified Agent to get your best Rental Property either Commercial or Residential
            </Text>
           
<Row gutter={[10, 10]} style={{marginTop:15}}>
      {/* Column for Make A Call Button */}
      <Col xs={24} sm={12}>
        <Button
          type="primary"
          icon={<PhoneOutlined style={styles.iconSpacing} />}
          style={styles.callButton}
          onClick={handleMakeCall}
          block // Makes the button full width
        >
          Make A Call
        </Button>
      </Col>
      {/* Column for Get Appointment Button */}
      <Col xs={24} sm={12}>
      <Button
          type="primary"
          icon={<CalendarOutlined style={styles.iconSpacing} />}
          style={styles.appointmentButton}
          onClick={handleGetAppointment}
          block // Makes the button full width
        >
          Get Appointment
        </Button>
      </Col>
    </Row>
          </Col>
        </Row>

        <Modal
          title="Appointment Form"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[{ required: true, message: 'Please enter your mobile number' }]}
            >
              <Input placeholder="Enter your mobile number" />
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: 'Please enter a message' }]}
            >
              <Input.TextArea placeholder="Enter your message" rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default ContactAgent;
