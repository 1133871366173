import { useEffect, useState } from "react";

const Banner = () => {
    const [bannerData, setBannerData] = useState(null);

    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const response = await fetch("https://api.bookarenthouse.com/banners");
                const data = await response.json();
                console.log('banner data', data);
                setBannerData(data[0]); // Assuming data is an array
            } catch (error) {
                console.error("Error fetching banner data:", error);
            }
        };

        fetchBannerData();
    }, []);

    if (!bannerData) {
        return <div>Loading...</div>;
    }

    const baseUrl = "https://api.bookarenthouse.com/"; // Your base URL
    const backgroundImage = bannerData.imageUrl ? `${baseUrl}${bannerData.imageUrl}` : 'path/to/default-image.jpg';

    return (
        <div className="banner d-flex align-items-center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="bg-custom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="banner-area text-center pt-4 pb-4">
                                <p>{bannerData.subtext}</p>
                                <h2 className="mt-2 mb-4 banner-title">
                                    <strong>{bannerData.mainText}</strong>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
