import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PropertyFilter = ({ selectedType }) => {
    const [properties, setProperties] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);
    const [animationClass, setAnimationClass] = useState('');
    const [location, setLocation] = useState('');
    const [area, setArea] = useState('');
    const hosturl = 'https://api.bookarenthouse.com';

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await fetch(`${hosturl}/api/properties`);
                const data = await response.json();
                const filteredProperties = data.filter(property => 
                    property.status === 'PAID' && // Filter only PAID properties
                    (property.type.toLowerCase() === selectedType || 
                     property.size.toLowerCase() === selectedType)
                );
                setProperties(filteredProperties);
                setFilteredProperties(filteredProperties);
                setAnimationClass('fade-in');
            } catch (error) {
                console.error("Error fetching properties:", error);
            }
        };

        fetchProperties();
    }, [selectedType]);

    const handleSearch = () => {
        const lowerLocation = location.toLowerCase();
        const lowerArea = area.toLowerCase();

        const newFilteredProperties = properties.filter(property => 
            (property.location.toLowerCase().includes(lowerLocation) || lowerLocation === '') &&
            (property.area.toString().toLowerCase().includes(lowerArea) || lowerArea === '')
        );

        setFilteredProperties(newFilteredProperties);
    };

    const cardStyle = {
        width: '260px',
        height: '320px',
        margin: '15px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
    };

    const imageStyle = (isHovered) => ({
        width: '100%',
        height: '160px',
        objectFit: 'cover',
        transition: 'transform 0.3s ease',
        transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    });

    const titleStyle = {
        color: '#3a4867de',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '5px 0',
    };

    const descriptionStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 10px',
    };

    const buttonStyle = (isHovered) => ({
        padding: '7px 15px',
        backgroundColor: 'green',
        color: '#fff',
        border: 'none',
        borderRadius: '25px',
        cursor: 'pointer',
        opacity: isHovered ? 1 : 0,
        transform: isHovered ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 0.3s ease, transform 0.3s ease',
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
    });

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ 
                marginBottom: '20px', 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'row', 
                alignItems: 'center', 
                gap: '10px', // Adds space between inputs and button
                flexWrap: 'wrap' // Wraps the row if needed on smaller screens
            }}>
                <input
                    type="text"
                    placeholder="Search by Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    style={{
                        padding: '10px',
                        width: '200px',
                        border: 'none',
                        borderRadius: '25px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                        transition: '0.3s',
                    }}
                    className="search-input"
                />
                <input
                    type="text"
                    placeholder="Search by Area (sq ft)"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                    style={{
                        padding: '10px',
                        width: '200px',
                        border: 'none',
                        borderRadius: '25px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                        transition: '0.3s',
                    }}
                    className="search-input"
                />
                <button 
                    onClick={handleSearch} 
                    style={{
                        padding: '7px 25px',
                        backgroundColor: 'blue',
                        color: 'white',
                        border: 'none',
                        borderRadius: '30px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}
                    className="search-button"
                >
                    Search
                </button>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <style>
                    {`
                        .fade-in {
                            opacity: 0;
                            animation: fadeIn 0.5s forwards;
                        }
                        @keyframes fadeIn {
                            to {
                                opacity: 1;
                            }
                        }
                        .slide-in-left {
                            animation: slideInLeft 0.5s forwards;
                        }
                        .slide-in-right {
                            animation: slideInRight 0.5s forwards;
                        }
                        @keyframes slideInLeft {
                            from {
                                transform: translateX(-100%);
                                opacity: 0;
                            }
                            to {
                                transform: translateX(0);
                                opacity: 1;
                            }
                        }
                        @keyframes slideInRight {
                            from {
                                transform: translateX(100%);
                                opacity: 0;
                            }
                            to {
                                transform: translateX(0);
                                opacity: 1;
                            }
                        }

                        /* Responsive styles */
                        .property-card {
                            flex: 0 0 21%;
                            margin: 15px;
                        }

                        @media (max-width: 1200px) {
                            .property-card {
                                flex: 0 0 29%; /* Adjust for medium screens */
                            }
                        }

                        @media (max-width: 992px) {
                            .property-card {
                                flex: 0 0 45%; /* Adjust for tablets */
                            }
                        }

                        @media (max-width: 768px) {
                            .property-card {
                                flex: 0 0 90%; /* Adjust for mobile screens */
                            }

                            .search-input {
                                width: 100%;
                                margin-bottom: 10px;
                            }

                            .search-button {
                                width: 100%;
                            }

                            /* Force the search inputs and button to be row-aligned on smaller screens */
                            .search-input, .search-button {
                                width: 100%; /* Take full width on small screens */
                            }
                        }

                        .search-input:focus {
                            box-shadow: 0 0 5px blue;
                        }
                        .search-button:hover {
                            background-color: darkblue;
                        }
                    `}
                </style>
                {filteredProperties.length > 0 ? (
                    filteredProperties.map((property, index) => {
                        const isHovered = hoveredId === property._id;
                        const slideDirection = index % 2 === 0 ? 'slide-in-left' : 'slide-in-right';

                        return (
                            <div 
                                className={`property-card text-center ${animationClass} ${slideDirection}`} 
                                key={property._id} 
                                style={cardStyle}
                                onMouseEnter={() => setHoveredId(property._id)}
                                onMouseLeave={() => setHoveredId(null)}
                            >
                                <div className="item-image">
                                    <img
                                        style={imageStyle(isHovered)}
                                        src={property.photos.length > 0 ? `${hosturl}/${property.photos[0]}` : '/path/to/default/image.jpg'}
                                        alt="flat"
                                    />
                                </div>
                                <h5 className="best-estate-title" style={titleStyle}>{property.type}</h5>
                                <Link to={`/flat/${property._id}`}>
                                    <button style={buttonStyle(isHovered)}>
                                        View Details
                                    </button>
                                </Link>
                                <div className="best-estate-content">
                                    <div style={descriptionStyle}>
                                        <div className="best-estate-features">
                                            <div className="best-estate-feature">
                                                <i className="fas fa-expand-arrows-alt"></i> {property.area} sq ft
                                            </div>
                                            <div className="best-estate-feature">
                                                <i className="fas fa-map-marker-alt"></i> {property.location}
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="best-estate-price">₹{property.rent}</h5>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p style={{ textAlign: 'center', width: '100%', margin: '20px 0' }}>
                        Currently, there are no properties available.
                    </p>
                )}
            </div>
        </div>
    );
};

export default PropertyFilter;
