import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoimage from '../logo-icon.jpg'
import { MenuOutlined } from "@ant-design/icons";
import './header.css';

const Header = ({ setSelectedType }) => {

    const [showSmall, setShowSmall] = useState(false)
    return (
        <>
            <div className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid" style={{ marginTop: 10 }}>
                            <Link className="navbar-brand" to="/">
                                <div className="d-flex align-items-center">
                                    <img src={logoimage} style={{ height: 40, width: 75 }} />

                                </div>
                            </Link>

                            <div className="navbar" id="navbarNav">
                                <button class="navbar-toggler" type="button" onClick={() => setShowSmall(!showSmall)} >
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <ul className="navbar-nav ms-auto big-menu">
                                    <li className="nav-item" >
                                        <Link className="nav-link" to="/"><e style={{ color: '#49bdc7' }}>Home</e></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about"><e style={{ color: '#49bdc7' }}>About</e></Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" id="propertiesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <e style={{ color: "#49bdc7" }}>Properties</e>                                    </Link>
                                        <ul className="dropdown-menu" aria-labelledby="propertiesDropdown">
                                            {['1RK', '1BHK', '2BHK', '3BHK', 'Duplex House'].map(type => (
                                                <li key={type}>
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/propertyfilter"
                                                        onClick={() => setSelectedType(type.toLowerCase())}
                                                    >
                                                        {type}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact"><e style={{ color: '#49bdc7' }}>Contact</e></Link>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://user.bookarenthouse.com/signin" className="btn ms-2 text-white" style={{ backgroundColor: '#49bdc7' }}  rel="noopener noreferrer">
                                            Add Property
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            {showSmall && <ul className="navbar-nav ms-auto small-menu">
                <li className="nav-item" >
                    <Link className="nav-link" to="/" onClick={() => setShowSmall(!showSmall)} ><e style={{ color: '#49bdc7' }}>Home</e></Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/about" onClick={() => setShowSmall(!showSmall)}><e style={{ color: '#49bdc7' }}>About</e></Link>
                </li>
                <li className="nav-item dropdown" >
                    <Link className="nav-link dropdown-toggle" to="#" id="propertiesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <e style={{ color: "#49bdc7" }}>Properties</e>                                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="propertiesDropdown">
                        {['1RK', '1BHK', '2BHK', '3BHK', 'Duplex House'].map(type => (
                            <li key={type}>
                                <Link
                                    className="dropdown-item"
                                    to="/propertyfilter"
                                    onClick={() => setSelectedType(type.toLowerCase())}
                                >
                                    {type}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" onClick={() => setShowSmall(!showSmall)} to="/contact"><e style={{ color: '#49bdc7' }}>Contact</e></Link>
                </li>
                <li className="nav-item">
                    <a href="https://user.bookarenthouse.com/signin" className="btn ms-2 text-white" style={{ backgroundColor: '#49bdc7' }} target="_blank" rel="noopener noreferrer">
                        Add Property
                    </a>
                </li>

            </ul>}

        </>
    );
};

export default Header;
