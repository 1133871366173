const About = () => {
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">About</h1>
                            <h2 className="page-description">About</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
            <div class="container">
              <h3>Welcome to Book a rent House !</h3>
                <p>Book a rent house is a rental platform that makes it possible to rent a house with paying
                    brokerage
                    Book a rent house was started because all of us believed that paying hefty brokerage
                    can not be the only option to find a new home. As tenants, we have been paying these
                    brokerages year on year without seeing any advantage of the broker. The only reason
                    he existed was that there was a huge information asymmetry in the market. Book a Rent
                    House is a platform that removes this information asymmetry and provides a
                    marketplace for free exchange of this information that used to cost only one month of
                    rent as brokerage.
                    </p>
                <p>We have done 2 things to help you find that perfect home:  </p>
                <li>Firstly, we have painstakingly verified each listing and made sure that these are
                    direct owners or shared accommodation parties and there are no middlemen or
                    brokers. We use lot of heuristics and techniques to ensure that you get a totally
                    broker free list.</li> 
                <li>Secondly, we have also tried to ensure that maximum information is available to
                    you in as easy to use format. This ensures that you get a very good idea of the
                    property even before you visit it. Thus, you can shortlist flats sitting at the comfort
                    of your home without actually traveling all the good and bad properties. This
                    saves your time and effort and with a quick shortlist of 4-5 properties you can
                    actually get a house in few hours!.</li> 
                <p> If you are a landlord interested in posting your apartments to Book a rent house,
                    please email us at hello@Book a rent house.com and we will get in touch to help
                    you list the property.
                    </p> 
                <p>And tenants, happy hunting and get in touch with us to let us know how else we
                    can help!</p>
                <p>Owners. : why you should list with us ?</p>  
                <p>Book a Rent House is a rental platform lets you find tenants without any broker
                    acting as a middleman. Apart from the fact that this can be much more
                    convenient and safe (you don't have to handover keys to an unknown person) it
                    will help you save time and money.</p>
                <p>Most brokers don't filter people and end up showing houses randomly. This
                    would mean that only when you meet the tenants you would come to know if that
                    person would not fit in the society rules. This leads to lot of inconvenience and
                    wastage of time. Also, brokers hoard the information and create the information
                    asymmetry. They decide who should not see your house because there is
                    another landlord who may give him little extra brokerage. While in case of Book a
                    rent House, you would be speaking to the potential tenant before you actually
                    show him the house. Even the key can be kept with the society secretary if you
                    do not want to go to the house every time the tenant wants to see. Many
                    landlords also fix up a day and time when they are there and all tenants can
                    come and see the house in that time.</p>
                <p>Also, you end up saving on brokerage that is anywhere between 15 days to 2
                    month's rent. Some brokers also charge this every year. When both you and
                    tenant are not paying the brokerage, tenants are also willing to pay a little higher
                    rent. So, you can expect to get 2-4% higher rent as compared to what brokers
                    may get you. It's a win-win for both the landlord and tenant.
                    </p>
                <p>that we just verify to make sure that no broker is listing. We also use innovative
                    and easy technology like whatsapp to help you list your property. If at any stage
                    you need any guidance, you can always speak to our customer service manager.
                    We also make sure that any person listing as tenant is not a broker, and thus you
                    do not get any of those pesky calls. Once the listing is done, you would start
                    getting enquiries from genuine tenants.
                    </p>
                <p>If you have a property to list you can click on 'Post Your Property' button on top
                    right corner of the page or email us at hello@Book a rent house.com   Tenants, : Benifits of Book a rent House .com.
                    Book a rent house rental platform lets you find your dream house without any broker acting as
                    a middleman. This is the most convenient way of finding your house without paying hefty
                    brokerage.
                    Book a rent house helps you in 3 ways:
                    We make sure that each property is verified and directly from owner. So, you can save on the
                    brokerage that varies from 15 days to 2 month's rent. And most brokers charge this every year
                    even if you are renewing the same property.
                    All the information is available in a well-organized manner with photographs and a detailed
                    analysis if the surroundings. You can locate where is the nearest school, grocery shop, ATM etc.
                    This information will help you shortlist the property without taking an effort to visit the actual site.
                    In case of brokers, they just want to make sure that the property goes out fast. They would
                    therefore show you properties without even thinking if it would suit your requirements. It would
                    mean that you would end up visiting lot more properties and realizing as soon as you reach there that this is not what you would want. With Book a Rent House, this convenience comes at
                    no cost and saves you lot of time and effort.
                    Brokers hoard information and create information asymmetry in the market. They would ideally
                    want that the not-so-good houses should go first as the good houses would anyway have lot of
                    takers. Therefore, they would always show you bad houses first and only when you reject all of
                    them would they start showing you better houses. In Book a rent House , there is no information
                    asymmetry. All houses inventory is available for you to pick and choose. It is on
                    first-come-first-serve basis. This is a big advantage that makes the market transparent and
                    efficient.
                    So, forget your old broker and find your dream house with Book a Rent House. Com Click here
                    to search for houses.</p>
        </div>
            </div>
        </section>
    )
}

export default About