import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import { Card, Button, Row, Col, } from 'antd';
import './FlatDetail.css';
import {
    ArrowsAltOutlined,
    // BuildingOutlined,
    MoneyCollectOutlined,
    WalletOutlined,
    ExpandOutlined,
    HomeOutlined,
    EnvironmentOutlined,
    CompassOutlined,
    CalendarOutlined,
    BorderInnerOutlined
} from '@ant-design/icons';
const FlatDetail = () => {
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [relatedProperties, setRelatedProperties] = useState([]);
    const [error, setError] = useState(null);
    const hosturl = 'https://api.bookarenthouse.com';
    const refs = useRef([]);
    const history = useHistory();
    const isAuthenticated = !!localStorage.getItem('token');

    useEffect(() => {
        const fetchPropertyDetail = async () => {
            if (!id) {
                setError("Property ID is missing.");
                return;
            }

            try {
                const response = await fetch(`${hosturl}/api/properties/${id}`);
                if (!response.ok) {
                    throw new Error(`Error ${response.status}: ${await response.text()}`);
                }
                const data = await response.json();
                setProperty(data);
                fetchRelatedProperties(data.type);
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchRelatedProperties = async (type) => {
            try {
                const response = await fetch(`${hosturl}/api/properties?type=${type}&status=PAID`); // Adjust the API call if necessary
                if (!response.ok) {
                    throw new Error("Failed to fetch related properties");
                }
                const data = await response.json();
                const filteredProperties = data.filter(property => property.status === 'PAID'); // Filter only PAID properties
                const shuffled = filteredProperties.sort(() => 0.5 - Math.random()).slice(0, 4);
                setRelatedProperties(shuffled);
            } catch (error) {
                console.error("Error fetching related properties:", error);
            }
        };

        fetchPropertyDetail();
    }, [id]);

    const handleViewDetailsClick = (relatedPropertyId) => {
        history.push(`/flat/${relatedPropertyId}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = refs.current.indexOf(entry.target);
                    entry.target.classList.add(index < 2 ? 'left-enter' : 'right-enter');
                    observer.unobserve(entry.target);
                }
            });
        });

        refs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            refs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, [relatedProperties]);

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    if (!property) {
        return <div className="loading">Loading property details...</div>;
    }

    const images = property.photos.map(photo => ({
        original: `${hosturl}/${photo}`,
        thumbnail: `${hosturl}/${photo}`,
    }));

    const handleOwnerDetailsClick = () => {
        const propertyDetailsUrl = `https://user.bookarenthouse.com/tenantpayment?propertyId=${property._id}&location=${encodeURIComponent(property.location)}&rent=${property.rent}`;

        if (!isAuthenticated) {
            window.location.href = `https://user.bookarenthouse.com/signin?redirect=${encodeURIComponent(propertyDetailsUrl)}`;
        } else {
            window.open(propertyDetailsUrl, '_blank');
        }
    };


    return (
        <div className="flat-detail">
            <div className="page-top" style={{ width: "100%" }}>
                <div className="container">
                    <h1 className="page-title">{property.type}</h1>
                    <h2 className="page-description">{property.location}</h2>
                </div>
            </div>
            {/* <div className="floating-card">
         <Card   title="Contact Owner" bordered={true} className="contact-card2" >
                            <p>Phone: +91 XXX-XXX-XXXX</p>
                            <Button type="primary" onClick={handleOwnerDetailsClick}>Owner Details</Button>
                        </Card>
      </div> */}
            <div className="m-3" style={{}}>

                <Row gutter={16}>
                    <Col xs={24} md={18}>
                        <div className="image-description-container">
                            <ImageGallery
                                items={images}
                                showNav={false}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                additionalClass="image-gallery" // Optional, for CSS targeting
                            />
                            <div className="fd-item" style={{ backgroundColor: "" }}>
                                <h4>Description</h4>
                                <PDetails property={property} />
                                {/* <div >
                        <p><i className="fas fa-expand-arrows-alt"></i> Area: {property.area} sq ft</p>
                        <p>Building: {property.building}</p>
                        <p>Rent: ₹{property.rent}</p>
                        <p>Advance: ₹{property.advance}</p>
                        <p>Size: {property.size}</p>
                        <p>Inner Facilities: {property.innerFacilities}</p>
                        <p>Nearby Facilities: {property.nearbyFacilities}</p>
                        <p>Facing: {property.face}</p>
                        <p>Uploaded On: {new Date(property.uploadDate).toLocaleDateString()}</p>
                            </div> */}
                                <div className="button-container">
                                    <Button type="primary" onClick={handleOwnerDetailsClick}>Owner Details</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className="contact-card" title="Contact Owner" bordered={true}  >
                            <p style={{ fontSize: '0.8rem' }}>Phone: +91 XXX-XXX-XXXX</p>
                            <Button type="primary" onClick={handleOwnerDetailsClick}>Owner Details</Button>
                        </Card>
                    </Col>
                </Row>


            </div>
            <div className="m-3">

                <h4>Related Properties</h4>
                <div className="related-properties ">
                    {relatedProperties?.length > 0 ? (
                        relatedProperties?.map((relatedProperty, index) => (
                            <div
                                className="best-estate"

                                key={relatedProperty._id}
                                ref={el => refs.current[index] = el}>
                                <div className="best-estate-img-area">
                                    <img className="best-estate-img" src={`${hosturl}/${relatedProperty.photos[0]}`} alt={relatedProperty.building} />
                                    <button
                                        className="view-details"
                                        onClick={() => handleViewDetailsClick(relatedProperty._id)}
                                    >
                                        View Details
                                    </button>
                                </div>
                                <div className="best-estate-content">
                                    <div>
                                        <h5 className="best-estate-title">{relatedProperty.type}</h5>
                                        <div className="best-estate-features">
                                            <div className="best-estate-feature">
                                                <i className="fas fa-expand-arrows-alt"></i> {relatedProperty.area} sq ft
                                            </div>
                                            <div className="best-estate-feature">
                                                <i className="fas fa-map-marker-alt"></i> {relatedProperty.location}
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="best-estate-price">₹{relatedProperty.rent}</h5>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Currently, there are no properties available.</p> // Message when no related properties are found
                    )}
                </div>
            </div>
        </div>
    );
};

export default FlatDetail;



const PDetails = ({ property }) => (
    //   <Row gutter={[16, 16]}>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}><i className="fas fa-expand-arrows-alt"></i>       <e style={{fontWeight:'bold'}}>Area: </e> 
    //       {property.area} sq ft</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}><e style={{fontWeight:'bold'}}>Building: </e> {property.building}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>    <e style={{fontWeight:'bold'}}>Rent: </e> 
    //       ₹{property.rent}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>    <e style={{fontWeight:'bold'}}>Advance: </e> 
    //     ₹{property.advance}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>    <e style={{fontWeight:'bold'}}>Size: </e> 
    //       {property.size}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>
    //       <e style={{fontWeight:'bold'}}>Inner Facilities: </e> 
    //        {property.innerFacilities}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>       <e style={{fontWeight:'bold'}}>Nearby Facilities: </e> 
    //        {property.nearbyFacilities}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>      <e style={{fontWeight:'bold'}}>Facing: </e> 
    //        {property.face}</p>
    //     </Col>
    //     <Col xs={24} sm={12}>
    //       <p style={{marginBottom:0}}>       <e style={{fontWeight:'bold'}}>Uploaded On: </e> 
    //       {new Date(property.uploadDate).toLocaleDateString()}</p>
    //     </Col>
    //   </Row>
    <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <ArrowsAltOutlined /> <span style={{ fontWeight: 'bold' }}>Area:</span> {property.area} sq ft
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <HomeOutlined /> <span style={{ fontWeight: 'bold' }}>Building:</span> {property.building}
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <MoneyCollectOutlined /> <span style={{ fontWeight: 'bold' }}>Rent:</span> ₹{property.rent}
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <WalletOutlined /> <span style={{ fontWeight: 'bold' }}>Advance:</span> ₹{property.advance}
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <ExpandOutlined /> <span style={{ fontWeight: 'bold' }}>Size:</span> {property.size}
            </p>
        </Col> <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <CompassOutlined /> <span style={{ fontWeight: 'bold' }}>Facing:</span> {property.face}
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <BorderInnerOutlined /> <span style={{ fontWeight: 'bold' }}>Inner Facilities:</span> {property.innerFacilities}
            </p>
        </Col>
        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <EnvironmentOutlined /> <span style={{ fontWeight: 'bold' }}>Nearby Facilities:</span> {property.nearbyFacilities}
            </p>
        </Col>

        <Col xs={24} sm={12}>
            <p style={{ marginBottom: 0 }}>
                <CalendarOutlined /> <span style={{ fontWeight: 'bold' }}>Uploaded On:</span> {new Date(property.uploadDate).toLocaleDateString()}
            </p>
        </Col>
    </Row>
);



