import React, { useEffect, useState } from 'react';

const FeedbackList = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await fetch('https://api.bookarenthouse.com/api/feedback');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFeedbacks(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  const handleNext = () => {
    if (currentIndex < feedbacks.length - 2) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (loading) {
    return <div>Loading feedbacks...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const displayedFeedbacks = feedbacks.slice(currentIndex, currentIndex + 2);

  return (
    <div className="feedback-container">
      <style>{`
        .feedback-container {
          background-color: #f9f9f9;
          padding: 20px;
          border-radius: 10px;
          margin: auto;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
          overflow: hidden;
          position: relative;
        }

        h2 {
          font-family: 'Montserrat', sans-serif;
          color: #333;
        }

        h3 {
          font-family: 'Lora', serif;
          color: #555;
          margin-bottom: 30px;
        }

        .feedback-navigation {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap; /* Allow items to wrap on small screens */
        }

        .feedback-cards {
          display: flex;
          justify-content: center;
          transition: opacity 0.5s ease;
          gap: 30px;
          flex-wrap: wrap; /* Wrap cards on smaller screens */
        }

        .feedback-card {
          flex: 0 0 45%;
          background: #fff;
          padding: 15px;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          min-width: 200px;
          max-width: 500px;
          height: 170px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        blockquote {
          font-style: italic;
          color: #555;
          margin: 10px 0 0;
          font-size: 14px;
        }

        .feedback-name {
          text-align: left;
          font-size: 12px;
          margin-top: auto;
        }

        .cta-button {
          background-color: #007BFF;
          color: white;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
        }

        .nav-button {
          background: #007BFF;
          color: white;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          font-size: 24px;
          padding: 10px;
          margin: 0 5px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .nav-button:disabled {
          background: #ccc;
          cursor: not-allowed;
        }

        @media (max-width: 768px) {
          .feedback-card {
            flex: 0 0 90%; /* Wider cards on mobile */
            height: auto; /* Allow height to adjust */
          }
          .feedback-cards {
            flex-direction: column; /* Stack cards vertically */
            align-items: center; /* Center align cards */
          }
        }
      `}</style>

      <h2>What Our Users Say</h2>
      <h3>Real Experiences, Real People</h3>
      
      <div className="feedback-navigation">
        <button className="nav-button" onClick={handlePrev} disabled={currentIndex === 0}>
          ❮
        </button>
        <div className="feedback-cards">
          {displayedFeedbacks.map(feedback => (
            <div key={feedback._id} className="feedback-card">
              <blockquote style={{fontSize:13,textAlign:'justify'}}>{feedback.message}</blockquote>
              <div className="feedback-name">
                <h6>{feedback.name} - {feedback.profession}</h6>
              </div>
            </div>
          ))}
        </div>
        <button className="nav-button" onClick={handleNext} disabled={currentIndex >= feedbacks.length - 2}>
          ❯
        </button>
      </div>
    </div>
  );
};

export default FeedbackList;
