import React, { useState, useEffect } from 'react';

const Contact = () => {
    const hosturl = 'https://api.bookarenthouse.com'; // Your API URL
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [contactInfo, setContactInfo] = useState(null);
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch(`${hosturl}/api/profile`);
                const data = await response.json();
                setContactInfo(data[0]); // Assuming the API returns an array
            } catch (error) {
                console.error("Error fetching contact info:", error);
            }
        };
        fetchContactInfo();
    }, []); // Add an empty dependency array to run only on mount

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, email, message } = formData;
        const mobile = formData.phone;

        if (!name || !email || !mobile || !message) {
            setFeedback('All fields are required');
            return;
        }

        try {
            const response = await fetch(`${hosturl}/submit_appointment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, mobile, message }),
            });

            if (response.ok) {
                setFeedback('Message sent successfully!');
                setFormData({ name: '', phone: '', email: '', message: '' }); // Reset form
            } else {
                const errorText = await response.text();
                setFeedback('Failed to send message: ' + errorText);
            }
        } catch (error) {
            console.error('Error:', error);
            setFeedback('Error sending message');
        }
    };

    return (
        <section className="contact">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Contact</h1>
                            <h2 className="page-description">Contact</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-envelope"></i>
                                        <h5>Mail</h5>
                                        <h6>{contactInfo ? contactInfo.email : 'Loading...'}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h5>Address</h5>
                                        <h6>{contactInfo ? contactInfo.location : 'Loading...'}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-phone-alt"></i>
                                        <h5>Phone</h5>
                                        <h6>{contactInfo ? contactInfo.phoneNumber : 'Loading...'}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row mt-5">
                                <h3 style={{fontWeight:'bold',color: '#253156', marginBottom:30}}>Get Appointment</h3>
                                    <div className="col-lg-6">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="inp-contact"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            className="inp-contact"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="inp-contact"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Message</label>
                                        <textarea
                                            name="message"
                                            className="ta-contact"
                                            rows="4"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="btn-contact">Send Message</button>
                                    </div>
                                    {feedback && <div className="feedback-message">{feedback}</div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
