import './App.css';
import FlatDetail from "./components/FlatDetail";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import BlogDetail from "./components/BlogDetail";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Privacypolicy from './components/PrivacyPolicy';
import Termsandconditions from './components/Termsandconditions';
import Refundpolicy from './components/RefundPolicy';
import PropertyFilter from './components/PropertyFilters';
import React, { useState } from 'react'; // Import useState

function App() {
  const [selectedType, setSelectedType] = useState(''); // State for selected type

  return (
    <Router>
      <div className="App">
        <Header setSelectedType={setSelectedType} /> {/* Pass the function as a prop */}
        <Route path="/" exact component={Home}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/privacypolicy" exact component={Privacypolicy}></Route>
        <Route path="/blog/:id" component={BlogDetail}></Route>
        <Route path="/flat/:id" component={FlatDetail}></Route>
        <Route path="/refundpolicy" component={Refundpolicy}></Route>
        <Route path="/termsandconditions" component={Termsandconditions}></Route>
        <Route path="/propertyfilter" render={() => <PropertyFilter selectedType={selectedType} />} /> {/* Use render prop to pass selectedType */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
