const Refundpolicy = () => {
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Refund Policy</h1>
                            <h2 className="page-description">Refund Policy</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
            <div class="container">
              {/* <h3>Refund Policy</h3> */}
                <p>In case Book a rent House is not able to facilitate transfer the advance
                    or Brokarage charge amount to the beneficiary account due to any technical
                    difficulties, Book a rent house will refund the payment back to the source.A
                    refund ID is generated and sent to through email and SMS. " We Refund the Money back within 72 Working Hours 
                    After get Approval of Refund"</p>
                <h5>GOVERNING LAW AND JURISDICTION </h5>
                <p>Any action, claim, dispute or difference arising out of or in connection with this
                    Agreement, including any question regarding its existence, validity or termination
                    (Dispute) shall be governed by and construed in accordance with the laws of India and
                    the courts in Guntur shall have exclusive jurisdiction over Disputes arising out of this
                    Agreement. Notwithstanding anything contained herein, Book a rent house shall not be
                    restricted or withheld from instituting proceedings in courts/ tribunals of any jurisdiction
                    other than Guntur that it may in its sole discretion deem appropriate and convenient.</p>    
        
            </div>
            </div>
        </section>
    )
}

export default Refundpolicy