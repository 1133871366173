import React, { useEffect, useState, useCallback } from 'react';

const TeamItem = ({ agent }) => {
    const imageUrl = `https://api.bookarenthouse.com/${agent.imagePath}`;

    return (
        <div style={styles.teamItem}>
            <div style={styles.team}>
                <div style={styles.teamImg}>
                    <img 
                        src={imageUrl} 
                        alt={agent.fullName} 
                        style={styles.image} 
                        onError={(e) => { 
                            e.target.onerror = null; 
                            e.target.src='https://via.placeholder.com/150'; 
                        }} 
                    />
                </div>
                <div style={styles.teamInfo}>
                    <h5 style={styles.teamName}>{agent.fullName}</h5>
                    <h6 style={styles.teamPosition}>{agent.designation}</h6>
                    {/* <div style={styles.socialLinks}>
                        <div style={styles.socialItem}><i className="fab fa-facebook-f"></i></div>
                        <div style={styles.socialItem}><i className="fab fa-twitter"></i></div>
                        <div style={styles.socialItem}><i className="fab fa-instagram"></i></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

const App = () => {
    const [agents, setAgents] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const fetchAgents = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.bookarenthouse.com/api/agents?page=${page}&limit=3`);
            const data = await response.json();
            setAgents(prevAgents => [...prevAgents, ...data]);
        } catch (error) {
            console.error('Error fetching agents:', error);
        } finally {
            setLoading(false);
        }
    }, [page]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const handleScroll = (e) => {
        const { scrollLeft, clientWidth, scrollWidth } = e.target;
        if (scrollLeft + clientWidth >= scrollWidth - 5 && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    };

    return (
        <div style={styles.container} onScroll={handleScroll}>
            <h1 style={styles.header}>Property Agents</h1>
            <div style={styles.scrollContainer}>
                {agents.map(agent => (
                    <TeamItem key={agent.id} agent={agent} />
                ))}
            </div>
            {loading && <div style={styles.loading}>Loading...</div>}
        </div>
    );
};

const styles = {
    container: {
        overflowX: 'auto',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        marginBottom: 30,
    },
    header: {
        fontSize: '2.0em',
        color: '#333',
        marginBottom: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontWeight: 'bold',
    },
    scrollContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100%',
        gap: '15px',
        flexWrap: 'wrap',
    },
    teamItem: {
        flex: '0 0 auto',
        width: '250px',
        transition: 'transform 0.3s',
        cursor: 'pointer',
        margin: '10px',
    },
    team: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '15px',
        textAlign: 'center',
        backgroundColor: '#fff',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s, transform 0.3s',
    },
    teamImg: {
        marginBottom: '10px',
    },
    image: {
        width: '100%',
        height: '210px',
        borderRadius: '5px',
        objectFit: 'cover',
    },
    teamInfo: {
        padding: '5px',
    },
    teamName: {
        fontWeight: 'bold',
        margin: '5px 0',
        color: '#333',
    },
    teamPosition: {
        color: '#777',
        margin: '5px 0',
    },
    socialLinks: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    socialItem: {
        margin: '0 5px',
        fontSize: '24px',
        color: '#00B98E', // Icon color
        backgroundColor: '#fff', // White background
        borderRadius: '50%', // Circle shape
        padding: '10px',
        width: '40px', // Fixed width for uniformity
        height: '40px', // Fixed height for uniformity
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Slight shadow for depth
        transition: 'background-color 0.3s, color 0.3s',
    },
    loading: {
        textAlign: 'center',
        marginTop: '10px',
        color: '#888',
    },
};

export default App;
